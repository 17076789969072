<template>
  <div>
    <div :class="{'rate--editable': canEdit,
                  'rate--with-error': errors && errors.length}"
         class="rate">
      <p v-if="title"
         class="rate__title"
         v-html="title"></p>
      <div class="rate__cont">
        <p v-for="(star, index) in stars"
           :key="index"
           :class="{'is-filled': getRate(star) <= rateValue}"
           class="rate__star"
           @click="catchRate(getRate(star))"></p>
      </div>
    </div>
    <div v-if="errors && errors.length"
         class="rate_errors-cont">
      <p v-for="(error, index) in errors"
         :key="index">
        {{ error }}
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: ''
      },
      rate: {
        type: [Number, String],
        default: 0
      },
      starsAmount: {
        type: [Number, String],
        default: 5
      },
      canEdit: Boolean,
      validationName: {
        type: String,
        default: ''
      },
    },
    computed: {
      stars() { return this.starsAmount || 5; },
      rateValue() { return this.rate ? Math.round(this.rate) : 0; },
      errors() {
        return this.$store.getters['ErrorsStore/getNamedErrors'](this.validationName);
      },
    },
    methods: {
      getRate(index) { return this.stars - (index - 1); }, // inverted order for hover effects on edit
      removeErrors() {
        this.$store.commit('ErrorsStore/setError', {name: this.validationName, errors: []});
      },
      catchRate(rateId) {
        if (this.canEdit) {
          this.removeErrors();
          this.$emit('ratechange', rateId);
        }
      }
    }
  };
</script>

<style scoped>
  .rate {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-height: 40px;
  }

  .rate__title {
    padding-right: 12px;
  }

  .rate__cont {
    display: flex;
    direction: rtl;
  }

  .rate__star {
    display: inline-block;
    width: 20px;
    height: 20px;
    padding: 1px;
    background-image: url(~@assets/imgs/checkboxes_icons/star_rate.svg);
    background-clip: content-box;
    background-size: 20px auto;
    background-repeat: no-repeat;
  }

  .rate--editable .rate__star {
    cursor: pointer;
  }

  .rate__star.is-filled,
  .rate--editable .rate__star:hover,
  .rate--editable .rate__star:hover ~ .rate__star {
    background-image: url(~@assets/imgs/checkboxes_icons/star_rate_checked.svg);
  }

  .rate--with-error {
    padding: 5px;
    border: 1px solid var(--color-error);
    border-radius: 4px;
  }
</style>
