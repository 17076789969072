import { render, staticRenderFns } from "./PersonalFeedbackBuyerSide.vue?vue&type=template&id=297626e6&scoped=true&"
import script from "./PersonalFeedbackBuyerSide.vue?vue&type=script&lang=js&"
export * from "./PersonalFeedbackBuyerSide.vue?vue&type=script&lang=js&"
import style0 from "./PersonalFeedbackBuyerSide.vue?vue&type=style&index=0&id=297626e6&prod&scoped=true&lang=css&"
import style1 from "./PersonalFeedbackBuyerSide.vue?vue&type=style&index=1&id=297626e6&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "297626e6",
  null
  
)

export default component.exports