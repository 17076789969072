<template>
  <div class="interpreter-info">
    <h4>{{ supplierName }}</h4>
    <div class="interpreter-info__phone">
      <h4>{{ $gettext('Tel: ') }}
        <a class="sk-link sk-link--default preview js-copy-to-clipboard">{{ supplierPhone }}</a>
      </h4>
    </div>
    <p v-if="interpreterAgreedWithTime"
       class="interpreter-info__response">
      {{ interpreterReportedText }}
    </p>
    <p class="interpreter-info__response">
      {{ pleaseCheckTelText }}
    </p>
  </div>
</template>
<script>
  import {mapGetters, mapState} from 'vuex';

  export default {
    computed: {
      ...mapGetters('FeedbackStore', [
        'otherSideStartTime',
        'otherSideFinishTime'
      ]),
      ...mapState('OneAssignmentStore', {
        businessDiscussions: ({chatDiscussions}) => chatDiscussions?.discussions,
        jobSupplierFeedback: ({storeJob}) => storeJob?.jobSupplierFeedback,
      }),
      ...mapState('FeedbackStore', {
        startTime: ({feedback}) => feedback.startTime,
        finishTime: ({feedback}) => feedback.finishTime,
      }),
      supplierName() {
        return this.businessDiscussions[0].supplierInfo?.name;
      },
      supplierPhone() {
        return this.businessDiscussions[0].supplierInfo?.phone;
      },
      interpreterAgreedWithTime() {
        let agreed = true;
        const reportedStartTime = this.$moment(this.jobSupplierFeedback?.reportedStartTime);
        const reportedFinishTime = this.$moment(this.jobSupplierFeedback?.reportedFinishTime);
        const startTime = this.$moment(this.startTime);
        const finishTime = this.$moment(this.finishTime);
        agreed = startTime === reportedStartTime;
        agreed = finishTime === reportedFinishTime;

        return agreed;
      },
      pleaseCheckTelText() {
        return this.$gettext('Please check that you called the interpreter on the telephone number provided');
      },
      interpreterReportedText() {
        return this.$gettext('The interpreter has reported that he / she met at the agreed time');
      },
    }
  };
</script>
<style scoped>
.interpreter-info {
  margin: 5px 0;
  padding: 16px;
  border-radius: 4px;
  background-color: #e6ebee;
}

.interpreter-info__response {
  margin: 10px 0;
  color: #b68e56;
}
</style>
