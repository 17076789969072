(<template>
  <div :class="{'in-progress': progressActive}"
       class="feedback pt-feedback is-progress-bar">
    <div v-if="!feedbackFinished && !createdCancelledAssignmentFeedback"
         class="feedback__title-wrapper">
      <h2 class="feedback__title">{{ $gettext('Reports') }}</h2>
      <p class="feedback__title-description"
         v-html="feedbackDeadline"></p>
    </div>
    <template v-if="userIsNotInterpreter">
      <!--  Feedback Step 1  -->
      <assignment-completion-section class="feedback__section"
                                     @submitsection="submitFeedbackSection" />
      <!--  Feedback Step 2  -->
      <interpreter-showed-up-section v-if="isJobNotCompleted"
                                     class="feedback__section"
                                     @submitsection="submitFeedbackSection" />
      <!--  Feedback Step - 3 -->
      <reported-duration-section v-if="isJobCompleted"
                                 class="feedback__section"
                                 @submitsection="submitFeedbackSection" />
      <!--  Feedback Step - 4 -->
      <other-problems-section v-if="showOtherProblems"
                              class="feedback__section"
                              @submitsection="submitFeedbackSection" />
      <!--  Feedback Step - 5 -->
      <personal-feedback-section v-if="showPersonalFeedback"
                                 class="feedback__section"
                                 @submitsectionv3="submitFeedbackSectionV3" />
      <finished-feedback v-if="showFinishedBlock"
                         class="feedback__section" />
      <!-- Overlap to Disable Locked Job Feedback   -->
      <div v-if="isJobLocked || feedbackFinished"
           class="feedback__locked-wrapper">
      </div>
    </template>
    <template v-else>
      <!--  Feedback Step 1  -->
      <assignment-travelling-section v-if="isCancelledInPersonFeedback"
                                     class="feedback__section" />
      <assignment-completion-section v-else
                                     class="feedback__section"
                                     @submitsection="submitFeedbackSection" />
      <!--  Feedback Step 2  -->
      <!-- hide before changes for templates -->
      <travel-feedback-cancelled-section v-if="isTravelling"
                                         class="feedback__section" />
      <travel-feedback-section v-if="showDidYouTravel"
                               class="feedback__section" />
      <!--  Feedback Step 3  -->
      <reported-duration-section v-if="!isSecretaryAssignment && showReportedDuration"
                                 class="feedback__section"
                                 @submitsection="submitFeedbackSection" />
      <invoice-info-section v-if="showInvoiceBlock"
                            class="feedback__section" />
      <travel-expenses-section v-if="showRegularTravelExpensesBlock"
                               class="feedback__section" />
      <personal-feedback-section v-if="jobFeedback.jobCompleted"
                                 class="feedback__section"
                                 @submitsectionv3="submitFeedbackSectionV3" />
      <!-- Overlap to disable submit button and show banner for cancelled assignemnt feedback   -->
      <finished-feedback v-if="createdCancelledAssignmentFeedback"
                         class="feedback__section" />
      <div v-if="createdCancelledAssignmentFeedback"
           class="feedback__locked-wrapper">
      </div>
    </template>
    <!-- ADMIN TOOLS-->
    <feedback-admin-tools v-if="$isGodmode()"
                          :show-travel-expenses-block="showTravelExpensesBlock" />
  </div>
</template>)

<script>
  import {mapActions, mapGetters, mapState} from 'vuex';
  import helpers from '@/helpers';
  import InvoiceInfoSection
    from '@/components/assignment_components/one_assignment/interactive_info/pt_feedback/feedback_sections/InvoiceInfoSection';
  import ReportedDurationSection
    from '@/components/assignment_components/one_assignment/interactive_info/pt_feedback/feedback_sections/reported_duration_section/ReportedDurationSection';
  import OtherProblemsSection
    from '@/components/assignment_components/one_assignment/interactive_info/pt_feedback/feedback_sections/other_problems_section/OtherProblemsSection';
  import PersonalFeedbackSection
    from '@/components/assignment_components/one_assignment/interactive_info/pt_feedback/feedback_sections/personal_feedback_section/PersonalFeedbackSection';
  import TravelExpenses
    from '@/components/assignment_components/one_assignment/interactive_info/pt_feedback/feedback_sections/travel_expenses_section/TravelExpensesSection';
  import AssignmentCompletionSection from '@/components/assignment_components/one_assignment/interactive_info/pt_feedback/feedback_sections/assignment_section/AssignmentCompletionSection';
  import InterpreterShowedUpSection from '@/components/assignment_components/one_assignment/interactive_info/pt_feedback/feedback_sections/interpreter_showedup_section/InterpreterShowedUpSection';
  import FinishedFeedback
    from '@/components/assignment_components/one_assignment/interactive_info/pt_feedback/feedback_sections/FinishedFeedback';
  import TravelFeedbackSection from '@/components/assignment_components/one_assignment/interactive_info/pt_feedback/feedback_sections/TravelFeedbackSection';
  import TravelFeedbackCancelledSection from '@/components/assignment_components/one_assignment/interactive_info/pt_feedback/feedback_sections/TravelFeedbackCancelledSection';
  import AssignmentTravellingSection from '@/components/assignment_components/one_assignment/interactive_info/pt_feedback/feedback_sections/assignment_section/AssignmentTravellingSection';

  export default {
    components: {
      'reported-duration-section': ReportedDurationSection,
      'other-problems-section': OtherProblemsSection,
      'assignment-completion-section': AssignmentCompletionSection,
      'assignment-travelling-section': AssignmentTravellingSection,
      'interpreter-showed-up-section': InterpreterShowedUpSection,
      'personal-feedback-section': PersonalFeedbackSection,
      'finished-feedback': FinishedFeedback,
      'invoice-info-section': InvoiceInfoSection,
      'travel-expenses-section': TravelExpenses,
      'travel-feedback-section': TravelFeedbackSection,
      'travel-feedback-cancelled-section': TravelFeedbackCancelledSection,
      'feedback-admin-tools': () => import('@/components/assignment_components/one_assignment/interactive_info/pt_feedback/admin_tools/FeedbackAdminTools'),
    },
    data() {
      return {
        progressActive: false,
        currentTime: this.$moment(),
        currentTimeTimer: ''
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userIsNotInterpreter',
        'userIsInterpreter',
      ]),
      ...mapGetters('OneAssignmentStore', [
        'jobFeedback',
        'currentDiscussion',
        'isSecretaryAssignment',
        'cancelledJobFeedback'
      ]),
      ...mapGetters('PostingNotifierStore', [
        'showInvoiceBlock'
      ]),
      ...mapGetters('AssignmentFlowStore', [
        'travelCostsPricing'
      ]),
      ...mapState('OneAssignmentStore', {
        assignmentObj: ({storeJobObj}) => storeJobObj || {}
      }),
      ...mapGetters('FeedbackStore', [
        'agreeWithDate',
        'disAgreeWithDate',
        'noProblem',
        'problemExists',
        'otherSideAgreedWithReportedDate',
        'feedbackFinished',
        'isTravelling'
      ]),
      ...mapState('FeedbackStore', {
        lastUpdatedData: ({lastUpdatedData}) => lastUpdatedData || {},
      }),
      isCancelledInPersonFeedback() {
        return this.assignmentObj.status === 'cancelled'
          && this.assignmentRequirements.sessionType === 'in_person';
      },
      jobId() {
        return this.$route.params.id || '';
      },
      assignmentRequirements() {
        return this.assignmentObj.interpretationRequirement || {};
      },
      lockWorkerPerformAt() {
        return this.assignmentObj.lockWorkerPerformAt;
      },
      feedbackDeadline() {
        const textTemplate = this.isJobLocked
          ? this.$gettext('Reporting closed')
          : this.$gettext('Reporting closes on:');
        const daysTemplate = this.$gettext('%{days}<span class="feedback__light-text">d</span>');
        const hoursTemplate = this.$gettext('%{hours}<span class="feedback__light-text">h</span>');
        const minutesTemplate = this.$gettext('%{minutes}<span class="feedback__light-text">m</span>');
        const feedbackDeadlineMoment = this.$moment(this.assignmentObj.lockWorkerPerformAt);
        const days = feedbackDeadlineMoment.diff(this.currentTime, 'days');
        const hours = feedbackDeadlineMoment.diff(this.currentTime, 'hours') % 24;
        const minutes = feedbackDeadlineMoment.diff(this.currentTime, 'minutes') % 60;
        const daysText = days ? this.$gettextInterpolate(daysTemplate, {days}) : '';
        const hoursText = hours ? this.$gettextInterpolate(hoursTemplate, {hours}) : '';
        const minutesText = minutes ? this.$gettextInterpolate(minutesTemplate, {minutes}) : '';
        const time = `${daysText} ${hoursText} ${minutesText}`.trim();

        return `${textTemplate} ${time}`;
      },
      feedbackId() {
        return this.jobFeedback.id || '';
      },
      isJobLocked() {
        return this.assignmentObj.locked || false;
      },
      isPoliceAssignment() {
        return this.assignmentObj.isPolice;
      },
      isJobCompleted() {
        return this.jobFeedback.jobCompleted === 'right';
      },
      isJobNotCompleted() {
        return this.jobFeedback.jobCompleted === 'wrong';
      },
      showedUp() {
        return this.jobFeedback.showedUp === true;
      },
      notShowedUp() {
        return this.jobFeedback.showedUp === false;
      },
      jobCompletedByColleague() {
        return this.jobFeedback.jobCompletedByColleague;
      },
      successModalTitle() {
        return this.$gettext('Thank you for your feedback!');
      },
      successModalParagraphs() {
        return [
          this.$gettext('Your feedback has been saved.'),
          this.$gettext('You can edit your feedback and save it again.'),
          this.$gettext('Your feedback will be locked once the assignment is sent for invoicing to our finance department.'),
        ];
      },
      showTravelExpensesBlock() {
        return this.userIsInterpreter && this.showTravelCost
          && this.isNotFlatAmount;
      },
      showRegularTravelExpensesBlock() {
        return this.showTravelExpensesBlock && (this.jobFeedback?.interpreterTravelled === 'right' || this.jobFeedback?.interpreterTravelled === null) && this.lastUpdatedData.supplier_feedback_updated_showed_up;
      },
      showDidYouTravel() {
        return this.assignmentRequirements.sessionType === 'in_person' && this.userIsInterpreter && this.jobFeedback?.jobCompleted;
      },
      showReportedDuration() {
        if (this.assignmentRequirements.sessionType === 'in_person') {
          return this.jobFeedback?.jobCompleted && this.lastUpdatedData.supplier_feedback_updated_showed_up;
        }
        return this.jobFeedback?.jobCompleted;
      },
      selectedTravelType() {
        return this.travelCostsPricing.travelType;
      },
      showTravelCost() {
        return ['in_person', 'videoroom'].includes(this.assignmentRequirements.sessionType);
      },
      isNotFlatAmount() {
        return helpers.priceTemplates.isNotFlatAmount(this.travelCostsPricing);
      },
      // discussion
      discussionId() {
        return this.currentDiscussion.id;
      },
      feedbackProblems() {
        return this.jobFeedback.problems;
      },
      showPersonalFeedback() {
        const option1 = this.isJobCompleted && this.otherSideAgreedWithReportedDate;
        const option2 = this.showedUp && this.noProblem;
        const option3 = this.feedbackProblems && this.feedbackProblems.length && this.problemExists;
        const option4 = this.isJobCompleted && this.disAgreeWithDate && this.jobFeedback.reportedFinishTime;

        return option1 || option2 || option3 || option4;
      },
      showFinishedBlock() {
        const option = this.notShowedUp || this.jobCompletedByColleague;
        return this.feedbackFinished || option;
      },
      showOtherProblems() {
        return this.isJobNotCompleted && this.showedUp;
      },
      createdCancelledAssignmentFeedback() {
        return this.cancelledJobFeedback.interpreterTravelled;
      }
    },
    methods: {
      ...mapActions('TravelExpensesStore', [
        'fetchTravelExpensesList'
      ]),
      reloadPage() {
        window.location.reload();
      },
      showFeedbackSentModal() {
        setTimeout(() => {
          this.$store.commit('InfoModalStore/setInfoModal', {
            text: this.genFeedbackSentMessage()
          });
        });
      },
      submitFeedbackSection(form, setProgress = () => {}, setFeedbackInfo = () => {}) {
        const jobId = this.jobId;
        const action = this.feedbackId ? 'FeedbackStore/updateFeedback' : 'FeedbackStore/sendFeedback';
        const params = {
          id: this.discussionId,
          feedback: form
        };

        setProgress(true);
        this.$store.dispatch(action, this.feedbackId ? form : params).then(() => {
          this.$store.dispatch('FeedbackStore/getLastUpdatedData', {jobId}).then(() => {
            setProgress(false);
            setFeedbackInfo();
            if (this.feedbackId) {
              this.showFeedbackSentModal();
            }
          });
        }).catch((error) => {
          let isHandledError = false;
          const errors = error?.data?.errors;

          this.$emit('postfeedbackfailed');
          if (error?.status == 401) {
            this.$store.dispatch('UserInfoStore/fetchUserInfo', this.$store.getters['UserInfoStore/userUid']).then(() => {
              isHandledError = true;
              setProgress(false);
              setTimeout(() => {
                this.$store.commit('InfoModalStore/setInfoModal', {
                  text: this.$gettext('Your rights in the Enterprise have been changed. Please try again.')
                });
              });
            });
          }
          if (errors?.id?.includes('Can\'t edit this feedback, confirmed feedback for this discussion created')) {
            isHandledError = true;
            setProgress(false);
            setTimeout(() => {
              this.$store.commit('InfoModalStore/setInfoModal', {
                text: this.$gettext('Your can\'t edit this feedback, confirmed feedback for this assignment already exist'),
                method: 'reloadPage',
                context: this
              });
            });
          }
          if (errors?.allowed_to_create_feedback?.includes('Feedback already created')
            || errors?.awaiting_for_feedback?.includes('Feedback is already exists or not available')) {
            isHandledError = true;
            setProgress(false);
            setTimeout(() => {
              this.$store.commit('InfoModalStore/setInfoModal', {
                text: this.$gettext('Please reload the page and try to edit existing feedback instead of submitting a new one.'),
                method: 'reloadPage',
                context: this
              });
            });
          }
          if (!isHandledError) {
            setProgress(false);
            setTimeout(() => {
              this.$store.commit('ModalStore/setModal', {
                component: 'error-modal',
                data: {
                  error
                }
              });
            });
          }
        });
      },
      submitFeedbackSectionV3(feedback, setProgress = () => {}) {
        const params = {
          discussionId: this.currentDiscussion.id,
          feedbackId: this.feedbackId,
          feedback: feedback
        };
        const action = this.feedbackId ? 'FeedbackStore/updateFeedbackV3' : 'FeedbackStore/sendFeedbackV3';
        setProgress(true);
        this.$store.dispatch(action, params).then(() => {
          setProgress(false);
          if (this.feedbackId && this.userIsInterpreter) {
            setTimeout(() => {
              this.$store.commit('InfoModalStore/setInfoModal', {
                text: this.genFeedbackSentMessage()
              });
            });
          }
        }).catch(() => {
          setProgress(false);
        });
      },
      genFeedbackSentMessage() {
        const title = `<h3>${this.successModalTitle}</h3>`;
        const paragraphs = this.successModalParagraphs.map((paragraph) => `<p>${paragraph}</p>`);
        return title + paragraphs.join('');
      },
      setFeedbackInfo() {
        this.$store.commit('PostInterpretationStore/setJobId', this.jobId);
        this.$store.commit('PostInterpretationStore/setJobInfo', {
          info: this.assignmentObj,
          rootGetters: this.$store.getters,
          rootState: this.$store.state
        });
      },
      setProgress(value) {
        this.progressActive = value;
      },
      fetchTravelExpenses() {
        const params = {
          jobId: this.jobId,
          discussionId: this.discussionId
        };
        this.setProgress(true);
        this.fetchTravelExpensesList(params).then(() => {
          this.setProgress(false);
        }).catch((error) => {
          this.setProgress(false);
          setTimeout(() => {
            this.$store.commit('ModalStore/setModal', {
              component: 'error-modal',
              data: {
                error
              }
            });
          }, 0);
        });
      },
    },
    mounted() {
      this.setFeedbackInfo();
      if (this.userIsInterpreter && this.isNotFlatAmount && this.showTravelCost && this.discussionId) {
        this.fetchTravelExpenses();
      }
      this.currentTimeTimer = setInterval(() => {
        this.currentTime = this.$moment();
      }, 1000);
    },
    destroyed() {
      clearInterval(this.currentTimeTimer);
    }
  };
</script>

<style>
  .feedback__field {
    margin-bottom: 20px;
  }

  .feedback__light-text {
    color: #9592ad;
  }

  .feedback__radios-wrapper {
    display: flex;
  }

  .feedback__radios-wrapper--full-width {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .feedback__radios-wrapper--full-width .feedback__radio-title {
    width: 100%;
    margin-bottom: 10px;
    padding-right: 0;
  }

  .feedback__radios-wrapper--full-width .feedback__radio {
    margin-right: 15px;
    margin-left: 0;
  }

  .feedback__radio-title {
    min-width: 50%;
    padding-right: 15px;
  }

  .feedback__radio {
    display: flex;
    align-items: center;
    margin-left: 15px;
  }

  @media (max-width: 767px) {
    .feedback__radios-wrapper {
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    .feedback__radio {
      margin-right: 15px;
      margin-left: 0;
    }

    .feedback__radio-title {
      width: 100%;
      margin-bottom: 10px;
      padding-right: 0;
    }
  }
</style>

<style scoped>
  .feedback {
    width: 100%;
  }

  .feedback__title-wrapper {
    padding: 20px 30px;
    background-color: #2b255c;
    color: #fff;
  }

  .feedback__title {
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 18px;
  }

  .feedback__section {
    margin-bottom: 10px;
  }

  .feedback__locked-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgb(255 255 255 / 20%);
  }
</style>
