(<template>
  <div class="relation-btn">
    <p v-if="description"
       class="relation-btn__descr"
       v-html="description"></p>
    <button :class="{'favourite-btn--favorite--chosen': isFavorUser}"
            class="favourite-btn favourite-btn--favorite"
            @click.stop.prevent="likeHandler">{{ favouriteBtnText }}</button>
  </div>
</template>)

<script>
  export default {
    props: {
      description: {
        type: String,
        default: ''
      },
      btnText: {
        type: String,
        default: ''
      },
      person: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      favouriteBtnText() { return this.btnText || this.$gettext('Favourite'); },
      isFavorUser() { return this.person.relation == 'favourite'; }
    },
    methods: {
      removeRelation() {
        this.$emit('startprogress');
        this.$store.dispatch('EditProfileStore/removeRelation', this.person.uid).then(() => {
          this.$emit('stopprogress');
          this.$emit('relationchanged', true);
        });
      },
      setFavor() {
        const form = new FormData();
        form.append('relation[interpreter]', this.person.uid);
        form.append('relation[attitude_type]', 'favourite');
        this.$emit('startprogress');
        this.$store.dispatch('EditProfileStore/sendUpdatedRelation', {form, relation: 'favourite'}).then(() => {
          this.$emit('stopprogress');
          this.$emit('relationchanged', true);
        });
      },
      likeHandler() {
        this.isFavorUser ? this.removeRelation() : this.setFavor();
      }
    }
  };
</script>

<style scoped src="@assets/css/relation_btn.css"></style>
