(<template>
  <feedback-section :title="title"
                    :icon="adminMode ? '' : 'invoice'"
                    :hide-button="true"
                    :progress-active="progressActive"
                    :class="{'travel-expenses--admin': adminMode}"
                    class="travel-expenses">
    <button slot="header"
            type="button"
            class="sk-btn sk-btn--default travel-expenses__btn"
            :disabled="!receiptConfirm"
            @click="addExpenses">{{ $gettext('Add') }}</button>
    <travel-expense v-for="expense in travelExpensesList"
                    :key="expense.key"
                    :expense="expense"
                    :admin-mode="adminMode"
                    class="travel-expenses__item"
                    @deleteexpense="catchExpenseRemove"
                    @editexpense="catchExpenseEdit" />
    <p class="travel-expenses__description">
      <b>{{ finalExpensesText }}</b>
    </p>
    <expense-warning-banner v-if="isInPersonJob"
                            :receipt-confirm="receiptConfirm"
                            :message="message"
                            :confirm-message="confirmMessage"
                            class="travel-expenses__expense-warning"
                            @confrimchanged="setReceiptConfirm" />
    <sk-note :text="maxAllowanceAmountText"
             class="travel-expenses__note" />
  </feedback-section>
</template>)

<script>
  import {mapState, mapActions, mapMutations, mapGetters} from 'vuex';
  import WarningBanner from '@/components/shared_components/banners/RecepitWarningBanner';
  import FeedbackSection
    from '@/components/assignment_components/one_assignment/interactive_info/pt_feedback/FeedbackSection';
  import TravelExpense
    from '@/components/assignment_components/one_assignment/interactive_info/pt_feedback/feedback_sections/travel_expenses_section/TravelExpense';

  export default {
    components: {
      'travel-expense': TravelExpense,
      'feedback-section': FeedbackSection,
      'expense-warning-banner': WarningBanner
    },
    props: {
      adminMode: Boolean
    },
    data() {
      return {
        progressActive: false,
        receiptConfirm: false
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userCurrency',
        'userIsInterpreter'
      ]),
      ...mapGetters('AssignmentFlowStore', [
        'travelCostsPricing'
      ]),
      ...mapState('TravelExpensesStore', {
        travelExpenses: ({travelExpenses}) => travelExpenses || {},
      }),
      ...mapState('OneAssignmentStore', {
        discussionId: ({chatDiscussions}) => chatDiscussions.discussion?.id || '',
        expenseSettings: ({storeJobObj}) => storeJobObj.expenseSettings || {}
      }),
      ...mapState('OneAssignmentStore', {
        assignmentObj: ({storeJobObj}) => storeJobObj || {}
      }),
      jobId() {
        return this.$route.params.id || '';
      },
      confirmMessage() {
        return this.$gettext('I am familiar with, and agree to be charged NOK 500 in administration fees for misleading or incorrect reporting.');
      },
      message() {
        return this.$gettext('<b>NB!</b> Deliberately erroneous and / or misleading reporting causes additional work.');
      },
      travelExpensesList() {
        const travelExpenses = [];

        for (const key of Object.keys(this.travelExpenses)) {
          travelExpenses.push({
            ...this.travelExpenses[key],
            key: Math.random(),
            maxAmount: (this.expenseSettings[key] || {}).max,
            type: key,
          });
        }

        return travelExpenses;
      },
      maxAllowanceAmount() {
        return this.travelCostsPricing.maxAllowanceAmount || 0;
      },
      maxAllowanceAmountText() {
        const template = this.$gettext('Customers have two limits for how much they can pay for travel expenses:<br>- limit for total amount of all expenses<br>- limit for amount per each expense type<br>This customer pays a maximum of <b>%{amount} %{currency}</b> for costs combined.');

        return this.$gettextInterpolate(template, {
          amount: this.maxAllowanceAmount,
          currency: this.currency
        });
      },
      currency() {
        return this.userCurrency?.name || 'NOK';
      },
      title() {
        return this.$gettext('Assignment expenses');
      },
      finalExpensesText() {
        return `${this.$gettext('Your final travel expenses:')} ${this.totalExpensesPrice} ${this.currency}`;
      },
      totalExpensesPrice() {
        return this.travelExpensesList.reduce((sum, currentItem) => sum + Number(currentItem.supplierApprovedAmount), 0) || 0;
      },
      isInPersonJob() {
        return this.assignmentObj.interpretationRequirement.sessionType === 'in_person';
      }
    },
    methods: {
      ...mapActions('TravelExpensesStore', [
        'removeExpense',
        'fetchTravelExpensesList'
      ]),
      ...mapMutations('TravelExpensesStore', [
        'clearStore'
      ]),
      catchExpenseRemove(id) {
        this.$store.commit('ModalStore/setModal', {
          component: 'remove-confirm',
          classes: ['remove-confirm'],
          width: 410,
          data: {
            title: this.$gettext('Delete expense?'),
            text: this.$gettext('Are you sure you want to delete this expense from your final expenses list?'),
            btnTexts: {
              removeBtnText: this.$gettext('Delete'),
              cancelBtnText: this.$gettext('Cancel')
            },
            id,
            context: this
          }
        });
      },
      catchExpenseEdit(expense) {
        this.$store.commit('ModalStore/setModal', {
          component: 'add-travel-expense',
          width: 410,
          classes: ['sk-modal--new'],
          data: {
            title: this.$gettext('Add expense'),
            maxAllowanceAmount: this.maxAllowanceAmount,
            adminMode: this.adminMode,
            expense
          }
        });
      },
      removeItem(id) {
        const params = {
          id,
          jobId: this.jobId,
          discussionId: this.discussionId
        };
        this.setProgress(true);
        this.removeExpense(params).then(() => {
          this.setProgress(false);
        }).catch((error) => {
          this.setProgress(false);
          setTimeout(() => {
            this.$store.commit('ModalStore/setModal', {
              component: 'error-modal',
              data: {
                error
              }
            });
          }, 0);
        });
      },
      addExpenses() {
        this.$store.commit('ModalStore/setModal', {
          component: 'add-travel-expense',
          width: 410,
          classes: ['sk-modal--new'],
          data: {
            title: this.$gettext('Add expense'),
            adminMode: this.adminMode,
            maxAllowanceAmount: this.maxAllowanceAmount,
          }
        });
      },
      fetchTravelExpenses() {
        const params = {
          jobId: this.jobId,
          discussionId: this.discussionId
        };
        this.setProgress(true);
        this.fetchTravelExpensesList(params).then(() => {
          this.setProgress(false);
        }).catch((error) => {
          this.setProgress(false);
          setTimeout(() => {
            this.$store.commit('ModalStore/setModal', {
              component: 'error-modal',
              data: {
                error
              }
            });
          }, 0);
        });
      },
      setReceiptConfirm(value) {
        this.receiptConfirm = value;
      },
      setProgress(value) {
        this.progressActive = value;
      }
    },
    mounted() {
      if (this.userIsInterpreter && !this.travelExpensesList.length) {
        this.fetchTravelExpenses();
      }
    },
    beforeDestroy() {
      this.clearStore();
    }
  };
</script>

<style>
  .travel-expenses__btn {
    width: auto;
    min-width: 60px;
    margin-left: auto;
    padding: 0 5px;
  }

  .travel-expenses--admin .feedback-section__title {
    align-items: center;
    font-size: 14px;
  }
</style>

<style scoped>
  .travel-expenses__description {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .travel-expenses__note {
    margin-bottom: 20px;
  }
</style>
