(<template>
  <feedback-section :title="title"
                    :icon="'complete'"
                    :button-description="lastUpdatedText"
                    :progress-active="progressActive"
                    :hide-button="true"
                    class="assignment-completion"
                    @save="save">
    <div class="assignment-completion__btns-wrapper">
      <button class="sk-btn feedback__toggle-btn"
              :class="{'sk-btn--default': isJobCompleted, 'sk-btn--white': !isJobCompleted, 'feedback__toggle-btn--lg': $isGodmode()}"
              @click="setJobCompleted('right'), save()">{{ $gettext('Yes') }}</button>
      <button class="sk-btn feedback__toggle-btn"
              :class="{'sk-btn--default': interpreterNotNeeded, 'sk-btn--white': !interpreterNotNeeded, 'feedback__toggle-btn--lg': $isGodmode()}"
              @click="setJobCompleted('interpreter_not_needed'), save()">{{ interpreterNotNeededText }}</button>
      <button class="sk-btn feedback__toggle-btn"
              :class="{'sk-btn--default': isJobNotCompleted, 'sk-btn--white': !isJobNotCompleted, 'feedback__toggle-btn--lg': $isGodmode()}"
              @click="setJobCompleted('wrong'), save()">{{ $gettext('No') }}</button>
    </div>
  </feedback-section>
</template>)

<script>
  import {mapGetters, mapMutations, mapState} from 'vuex';
  import helpers from '@/helpers';
  import FeedbackSection
    from '@/components/assignment_components/one_assignment/interactive_info/pt_feedback/FeedbackSection';

  export default {
    components: {
      'feedback-section': FeedbackSection
    },
    data() {
      return {
        progressActive: false
      };
    },
    computed: {
      ...mapState('FeedbackStore', {
        jobCompleted: ({feedback}) => feedback.jobCompleted,
        lastUpdatedData: ({lastUpdatedData}) => lastUpdatedData || {}
      }),
      ...mapGetters('OneAssignmentStore', [
        'jobFeedback',
        'currentDiscussion'
      ]),
      ...mapGetters('UserInfoStore', [
        'isShared',
        'userIsNotInterpreter',
        'userIsInterpreter'
      ]),
      ...mapGetters('FeedbackStore', [
        'isJobCompleted',
        'isJobNotCompleted',
        'interpreterNotNeeded'
      ]),
      lastUpdatedSectionData() {
        return this.userIsInterpreter
          ? this.lastUpdatedData.supplier_feedback_updated_job_completed
          : this.lastUpdatedData.demander_feedback_updated_job_completed;
      },
      title() {
        return this.$gettext('Was the assignment completed as ordered?');
      },
      storedJobCompleted() {
        return this.jobFeedback.jobCompleted || '';
      },
      lastUpdatedText() {
        return helpers.feedbackInfo.lastUpdatedText({
          context: this,
          lastUpdatedSectionData: this.lastUpdatedSectionData
        });
      },
      intNotNeededCustomerText() {
        return this.$gettext('We don\'t need an interpreter anymore');
      },
      intNotNeededInterpreterText() {
        return this.$gettext('Interpretation was not needed / they did not call');
      },
      interpreterNotNeededText() {
        return this.userIsInterpreter ? this.intNotNeededInterpreterText : this.intNotNeededCustomerText;
      },
      feedbackId() {
        return this.jobFeedback.id || '';
      },
    },
    methods: {
      ...mapMutations('FeedbackStore', [
        'setJobCompleted'
      ]),
      setFeedbackInfo() {
        this.setJobCompleted(this.jobFeedback.jobCompleted || localStorage.jobCompleted || '');
      },
      setProgress(value) {
        this.progressActive = value;
      },
      reloadPage() {
        window.location.reload();
      },
      save() {
        if (this.jobCompleted) {
          const params = {
            discussionId: this.currentDiscussion.id,
            feedbackId: this.feedbackId,
            feedback: {
              job_completed: this.jobCompleted
            }
          };
          if (this.isJobCompleted) params.feedback.showed_up = true;
          const action = this.storedJobCompleted ? 'FeedbackStore/updateFeedbackV3' : 'FeedbackStore/sendFeedbackV3';
          this.setProgress(true);
          this.$store.dispatch(action, params).then(() => {
            this.setProgress(false);
          }).catch((error) => {
            this.setProgress(false);
            const errors = error?.data?.errors;
            if (errors?.allowed_to_create_feedback?.includes('Feedback already created')
              || errors?.awaiting_for_feedback?.includes('Feedback is already exists or not available')) {
              setTimeout(() => {
                this.$store.commit('InfoModalStore/setInfoModal', {
                  text: this.$gettext('Please reload the page and try to edit existing feedback instead of submitting a new one.'),
                  method: 'reloadPage',
                  context: this
                });
              });
            }
          });
        }
      }
    },
    mounted() {
      this.setFeedbackInfo();
    }
  };
</script>

<style scoped>
.assignment-completion__btns-wrapper {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 10px;
}

.feedback__toggle-btn {
  margin-right: 4px;
  padding: 9px 10px;
  white-space: normal;
}

@media (max-width: 550px) {
  .feedback__toggle-btn {
    line-height: 20px;
  }
}
</style>
