(<template>
  <feedback-section :title="title"
                    :icon="'travel'"
                    :button-description="lastUpdatedText"
                    :progress-active="progressActive"
                    :hide-button="true"
                    :hint-text="hintText"
                    class="did-travel-section"
                    @save="save">
    <div class="did-travel-section__btns-wrapper">
      <button class="sk-btn feedback__toggle-btn"
              :class="{'sk-btn--default': didTravelToAddress, 'sk-btn--white': !didTravelToAddress}"
              @click="saveTravelFeedback('yes')">{{ $gettext('Yes') }}</button>
      <button class="sk-btn feedback__toggle-btn"
              :class="{'sk-btn--default': didNotTravelToAddress, 'sk-btn--white': !didNotTravelToAddress}"
              @click="saveTravelFeedback('no')">{{ $gettext('No') }}</button>
      <button class="sk-btn feedback__toggle-btn"
              :class="{'sk-btn--default': assignmentChangedToPhone, 'sk-btn--white': !assignmentChangedToPhone}"
              @click="saveTravelFeedback('assignment_changed_to_phone')">{{ $gettext('Assignment changed to phone') }}</button>
      <button class="sk-btn feedback__toggle-btn"
              :class="{'sk-btn--default': didNotShowUOnTime, 'sk-btn--white': !didNotShowUOnTime}"
              @click="saveTravelFeedback('i_did_not_show_up')">{{ $gettext('I did not show up on time') }}</button>
    </div>
  </feedback-section>
</template>)

<script>
  import {mapGetters, mapState, mapActions, mapMutations} from 'vuex';
  import helpers from '@/helpers';
  import FeedbackSection
    from '@/components/assignment_components/one_assignment/interactive_info/pt_feedback/FeedbackSection';

  export default {
    components: {
      'feedback-section': FeedbackSection
    },
    data() {
      return {
        progressActive: false
      };
    },
    computed: {
      ...mapGetters('OneAssignmentStore', [
        'jobFeedback',
        'currentDiscussion'
      ]),
      ...mapGetters('UserInfoStore', [
        'userIsInterpreter'
      ]),
      ...mapGetters('FeedbackStore', [
        'showedUp',
        'notShowedUp',
        'jobCompletedByColleague',
        'didNotTravelToAddress',
        'assignmentChangedToPhone',
        'didNotShowUOnTime',
        'didTravelToAddress'
      ]),
      ...mapState('FeedbackStore', {
        lastUpdatedData: ({lastUpdatedData}) => lastUpdatedData || {},
        problemDescription: ({feedback}) => feedback.problemDescription,
        problems: ({feedback}) => feedback.problems || [],
        problemsErrorName: ({feedback}) => feedback.errors.problems.name,
        didTravelState: ({didTravelToAddress}) => didTravelToAddress
      }),
      supplierInfo() {
        return this.currentDiscussion.supplierInfo;
      },
      lastUpdatedSectionData() {
        return this.userIsInterpreter
          ? this.lastUpdatedData.supplier_feedback_updated_showed_up
          : this.lastUpdatedData.demander_feedback_updated_assignment;
      },
      title() {
        const template = this.$gettext('Did you travel to the address?');
        return this.$gettextInterpolate(template, {name: this.supplierInfo.name});
      },
      travelStateForRequest() {
        if (this.didTravelState === 'yes') {
          return 'right';
        } else if (this.didTravelState === 'no') {
          return 'wrong';
        } else if (this.didTravelState === 'assignment_changed_to_phone') {
          return 'changed_session_type';
        } else if (this.didTravelState === 'i_did_not_show_up') {
          return 'not_showed_up';
        }
      },
      lastUpdatedText() {
        return helpers.feedbackInfo.lastUpdatedText({
          context: this,
          lastUpdatedSectionData: this.lastUpdatedSectionData
        });
      },
      feedbackId() {
        return this.jobFeedback.id || '';
      },
      hintText() {
        return this.$gettext('Select "Yes" if you started the journey');
      }
    },
    methods: {
      ...mapActions('FeedbackStore', [
        'setShowedUp',
        'setJobCompletedByColleague',
        'removeFeedbackErrors',
        'setFeedbackError'
      ]),
      ...mapMutations('FeedbackStore', [
        'setDidTravelToAddress'
      ]),
      getProblem(problemId) {
        return this.problems.find((item) => item == problemId);
      },
      setFeedbackInfo() {
        let didTravelField = '';
        const notShowedUpField = this.$store.state.OneAssignmentStore.storeJobObj?.jobSupplierFeedback?.showedUp === false ? 'i_did_not_show_up' : '';
        if (this.$store.state.OneAssignmentStore.storeJobObj?.jobSupplierFeedback?.interpreterTravelled) {
          didTravelField = this.$store.state.OneAssignmentStore.storeJobObj.jobSupplierFeedback.interpreterTravelled;
        } else if (notShowedUpField) didTravelField = notShowedUpField;
        this.setShowedUp(this.jobFeedback?.showedUp || notShowedUpField || '');
        this.setJobCompletedByColleague(this.jobFeedback.jobCompletedByColleague || false);
        this.setDidTravelToAddress(didTravelField || this.didNotTravelToAddress || localStorage.didTravelToAddress || '');
      },
      setProgress(value) {
        this.progressActive = value;
      },
      validate() {
        return this.jobCompletedByColleague || this.showedUp !== '';
      },
      saveTravelFeedback(condition) {
        this.setDidTravelToAddress(condition);
        this.save();
      },
      save() {
        if (this.validate()) {
          let params = {};
          if (this.travelStateForRequest === 'not_showed_up') {
            params = {
              discussionId: this.currentDiscussion.id,
              feedbackId: this.feedbackId,
              feedback: {
                showed_up: false,
                interpreter_travelled: null
              }
            };
          } else {
            params = {
              discussionId: this.currentDiscussion.id,
              feedbackId: this.feedbackId,
              feedback: {
                interpreter_travelled: this.travelStateForRequest,
                showed_up: true
              }
            };
          }
          const action = this.feedbackId ? 'FeedbackStore/updateFeedbackV3' : 'FeedbackStore/sendFeedbackV3';
          this.setProgress(true);
          this.$store.dispatch(action, params).then(() => {
            this.setProgress(false);
          }).catch(() => {
            this.setProgress(false);
          });
        }
      }
    },
    mounted() {
      this.setFeedbackInfo();
    }
  };
</script>

<style scoped>
.did-travel-section .feedback__toggle-btn {
  margin-right: 4px;
  padding: 5px 10px;
  line-height: 20px;
  white-space: normal;
}

.did-travel-section__btns-wrapper {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 10px;
}

.did-travel-section__info {
  margin-bottom: 20px;
}
</style>
