(<template>
  <feedback-section :title="title"
                    :icon="'problems'"
                    :button-description="lastUpdatedText"
                    :progress-active="progressActive"
                    :hide-button="!problemExists || !problemDescription"
                    class="other-problems"
                    @save="save">
    <div class="other-problems__btns-wrapper">
      <button class="sk-btn feedback__toggle-btn"
              :class="{'sk-btn--default': problemExists, 'sk-btn--white': !problemExists}"
              @click="setAnyOtherProblem(true)">{{ $gettext('Yes') }}</button>
      <button class="sk-btn feedback__toggle-btn"
              :class="{'sk-btn--default': disableButton, 'sk-btn--white': !disableButton}"
              @click="setAnyOtherProblem(false)">{{ $gettext('No') }}</button>
    </div>
    <div v-if="problemExists"
         class="other-problems__content-wrapper">
      <div v-if="ticketIds"
           class="sk-link tickets-list__link"
           @click="openTicketsList">{{ $gettext('Previous complaints') }}</div>
      <sk-multiselect :description="problemSelectLabel"
                      :items-list="problemsList"
                      :preselected-value="problems"
                      :default-value="problemPlaceholder"
                      :validation-name="problemsErrorName"
                      class="other-problems__select"
                      @checkboxeschanged="setFeedbackProblems" />
      <div class="other-problems__problem-description">
        <p class="other-problems__textarea-title">{{ messageTitleText }}</p>
        <sk-textarea
          :preselected-value="problemDescription"
          :placeholder="problemDescriptionPlaceholder"
          class="other-problems__textarea"
          @fieldvaluechanged="setProblemDescription" />
      </div>
    </div>
  </feedback-section>
</template>)

<script>
  import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
  import helpers from '@/helpers';
  import FeedbackSection
    from '@/components/assignment_components/one_assignment/interactive_info/pt_feedback/FeedbackSection';

  export default {
    components: {
      'feedback-section': FeedbackSection
    },
    data() {
      return {
        progressActive: false
      };
    },
    computed: {
      ...mapGetters('OneAssignmentStore', [
        'storeJob',
        'jobFeedback',
        'currentDiscussion'
      ]),
      ...mapGetters('UserInfoStore', [
        'userIsInterpreter',
        'userIsNotInterpreter'
      ]),
      ...mapGetters('FeedbackStore', [
        'problemExists',
        'noProblem'
      ]),
      ...mapState('FeedbackStore', {
        jobCompleted: ({feedback}) => feedback.jobCompleted,
        lastUpdatedData: ({lastUpdatedData}) => lastUpdatedData || {},
        problemDescription: ({feedback}) => feedback.problemDescription || '',
        problems: ({feedback}) => feedback.problems || [],
        problemsErrorName: ({feedback}) => feedback.errors.problems.name,
        anyOtherProblem: ({feedback}) => feedback.anyOtherProblem
      }),
      disableButton() {
        if (this.anyOtherProblem === null) {
          if (typeof window !== 'undefined') {
            if (localStorage.anyOtherProblem !== undefined) {
              this.setAnyOtherProblem(localStorage.anyOtherProblem);
              return !localStorage.anyOtherProblem;
            }
          }
          return false;
        }
        return !this.anyOtherProblem;
      },
      ticketIds() {
        if (this.storeJob.ticketsIds?.default && this.storeJob.ticketsIds?.otherFeedbackProblemOccurred) {
          return [
            ...this.storeJob.ticketsIds?.otherFeedbackProblemOccurred,
            ...this.storeJob.ticketsIds?.default
          ];
        } else if (!this.storeJob.ticketsIds?.default) {
          return this.storeJob.ticketsIds?.otherFeedbackProblemOccurred;
        } else {
          return this.storeJob.ticketsIds?.default;
        }
      },
      lastUpdatedSectionData() {
        return this.userIsInterpreter
          ? this.lastUpdatedData.supplier_feedback_updated_assignment
          : this.lastUpdatedData.demander_feedback_updated_problems;
      },
      title() {
        return this.$gettext('Do any other problems occur?');
      },
      storedJobCompleted() {
        return this.jobFeedback.jobCompleted || '';
      },
      lastUpdatedText() {
        return helpers.feedbackInfo.lastUpdatedText({
          context: this,
          lastUpdatedSectionData: this.lastUpdatedSectionData
        });
      },
      feedbackId() {
        return this.jobFeedback.id || '';
      },
      messageTitleText() {
        return this.$gettext('Please give us a brief description of the situation');
      },
      problemDescriptionPlaceholder() {
        return this.$gettext('Write message here.');
      },
      problemSelectLabel() {
        return this.$gettext('Why was the assignment not carried out as ordered?');
      },
      problemPlaceholder() {
        return this.$gettext('Please select one');
      },
      problemsList() {
        const getFeedbackProblemText = helpers.feedbackInfo.feedbackProblem.bind(
          null,
          this,
          this.userIsNotInterpreter
        );

        return [{
          id: 'norwegian_acceptable',
          name: getFeedbackProblemText('norwegian_acceptable')
        }, {
          id: 'quiet_env',
          name: getFeedbackProblemText('quiet_env')
        }, {
          id: 'good_phone_connection',
          name: getFeedbackProblemText('good_phone_connection')
        }, {
          id: 'conflict_of_interest',
          name: getFeedbackProblemText('conflict_of_interest')
        }];
      },
    },
    methods: {
      ...mapMutations('FeedbackStore', [
        'setAnyOtherProblem',
        'setJobCompleted',
        'setFeedbackProblems',
        'setProblemDescription'
      ]),
      ...mapActions('FeedbackStore', [
        'removeFeedbackErrors',
        'setFeedbackError'
      ]),
      getProblem(problemId) {
        return this.problems.find((item) => item == problemId);
      },
      setFeedbackInfo() {
        if (this.jobFeedback.problems.length) {
          let filteredProblemsList = [];
          // BE sending different id than FE mapping done here
          filteredProblemsList = this.jobFeedback.problems.map((problem) => {
            if (problem == 'noisy_env') return 'quiet_env';
            else if (problem == 'bad_phone_connection') return 'good_phone_connection';
            else return problem;
          });
          this.setFeedbackProblems(filteredProblemsList || []);
        }
        if (this.storeJob.ticketsIds?.default?.length) {
          this.setAnyOtherProblem(true);
        }
        this.setProblemDescription(this.jobFeedback.comment || localStorage.problemDescription);
      },
      setProgress(value) {
        this.progressActive = value;
      },
      validate() {
        let isValidForm = true;

        this.removeFeedbackErrors();
        if (this.problems && this.problems.length === 0) {
          isValidForm = false;
          this.setFeedbackError({
            fieldName: 'problems',
            errorText: this.$gettext('Please select at least one problem.')
          });
        }

        return isValidForm;
      },
      save() {
        if (this.validate()) {
          const params = {
            discussionId: this.currentDiscussion.id,
            feedbackId: this.feedbackId,
            feedback: {}
          };
          this.getProblem('norwegian_acceptable') ? params.feedback.norwegian_acceptable = false : params.feedback.norwegian_acceptable = true;
          this.getProblem('quiet_env') ? params.feedback.quiet_env = false : params.feedback.quiet_env = true;
          this.getProblem('good_phone_connection') ? params.feedback.good_phone_connection = false : params.feedback.good_phone_connection = true;
          // TODO: {Hotfix} needs to be changed to value false like the other problems
          // After BE is Updated
          this.getProblem('conflict_of_interest') ? params.feedback.conflict_of_interest = true : params.feedback.conflict_of_interest = false;
          if (this.problemDescription) {
            params.feedback.ticket_comment = this.problemDescription;
            params.feedback.ticket_context = 'other_feedback_problem_occurred';
          }

          const action = this.storedJobCompleted ? 'FeedbackStore/updateFeedbackV3' : 'FeedbackStore/sendFeedbackV3';
          this.setProgress(true);
          this.$store.dispatch(action, params).then(() => {
            this.setProgress(false);
          }).catch(() => {
            this.setProgress(false);
          });
        }
      },
      openTicketsList() {
        this.$store.commit('ModalStore/setModal', {
          component: 'tickets-list',
          width: 410,
          data: {
            title: this.$gettext('Complaint tickets list'),
            ticketsList: this.ticketIds
          }
        });
      }
    },
    mounted() {
      this.setFeedbackInfo();
    }
  };
</script>

<style scoped>
.other-problems__btns-wrapper {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.other-problems__textarea {
  height: 80px;
}

.other-problems__select {
  margin: 20px 0;
}

.other-problems__textarea-title {
  margin-bottom: 5px;
}

.feedback__toggle-btn {
  margin-right: 20px;
}

.tickets-list__link {
  position: absolute;
  bottom: 60px;
  left: 70px;
  z-index: 2;
  width: auto;
  height: auto;
}
</style>
