import { render, staticRenderFns } from "./FinishedFeedback.vue?vue&type=template&id=4390479c&scoped=true&"
var script = {}
import style0 from "./FinishedFeedback.vue?vue&type=style&index=0&id=4390479c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4390479c",
  null
  
)

export default component.exports