export default {
  methods: {
    reloadPage() {
        window.location.reload();
    },
    sendFeedback(discussionId) {
      this.progressActive = true;
      this.$store.dispatch('FeedbackStore/sendFeedbackForCancelledAssignment', discussionId).then(() => {
        this.reloadPage();
      }).catch((error) => {
        this.progressActive = false;
        if (error?.data?.errors?.interpreter_travelled?.includes('must not be assigned to existing account')) {
          setTimeout(() => {
            this.$store.commit('InfoModalStore/setInfoModal', {
              text: this.$gettext('You can\'t edit this feedback, confirmed feedback for this assignment already exist'),
              method: 'reloadPage',
              context: this
            });
          });
        }
        if (error?.data?.errors?.interpreter_travelled?.includes('Feedback already created')) {
          setTimeout(() => {
            this.$store.commit('InfoModalStore/setInfoModal', {
              text: this.$gettext('Feedback already created'),
              method: 'reloadPage',
              context: this
            });
          });
        }
      });
    }
  }
};
