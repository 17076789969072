(<template>
  <feedback-section :title="title"
                    :icon="sectionIcon"
                    :button-description="lastUpdatedText"
                    :hide-button="hideSave"
                    :progress-active="progressActive"
                    class="reported-duration-section"
                    @save="save">
    <div class="reported-duration-section__wrapper">
      <div>
        <div class="reported-duration_date-wrapper">
          <div class="reported-duration_date--start">
            <h4 class="reported-duration_date-title">{{ startTimeText }}</h4>
            <h4 class="reported-duration_date"
                v-html="startTimeDate"></h4>
          </div>
          <div class="reported-duration_date--end">
            <h4 class="reported-duration_date-title">{{ finishTimeText }}</h4>
            <h4 class="reported-duration_date"
                v-html="finishTimeDate"></h4>
          </div>
        </div>
        <div class="reported-duration__time--duration">
          <p class="reported-duration__duration-title">{{ $gettext('New duration') }}</p>
          <p class="reported-duration__duration-text"
             v-html="durationText"></p>
        </div>
        <div class="reported-duration__action-btns-wrapper">
          <button class="sk-btn feedback__toggle-btn"
                  :class="{'sk-btn--default': agreeWithDate, 'sk-btn--white': !agreeWithDate}"
                  @click="agreeWithReportedDateMethod">{{ iAgreeText }}</button>
          <button class="sk-btn feedback__toggle-btn"
                  :class="{'sk-btn--default': disableButton, 'sk-btn--white': !disableButton}"
                  @click="setAgreeWithReportedDate(false)">{{ iDisagreeText }}</button>
        </div>
      </div>
      <reported-duration v-show="disAgreeWithDate && disableButton"
                         :start-time="startTime"
                         :finish-time="finishTime"
                         :updated-supplier-feedback="updatedSupplierFeedback"
                         :other-side-start="otherSideStartTime"
                         :other-side-finish="otherSideFinishTime"
                         :time-step="timeStep"
                         :disable-editing="!feedbackUpdateAllowed"
                         :errors="durationValidationErrors"
                         class="feedback__field reported-duration-section__duration"
                         @setstarttime="setFeedbackStartTime"
                         @setfinishtime="setFeedbackFinishTime"
                         @setfinishdate="setFeedbackFinishDate" />
    </div>
  </feedback-section>
</template>)

<script>
  import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
  import helpers from '@/helpers';
  import FeedbackSection
    from '@/components/assignment_components/one_assignment/interactive_info/pt_feedback/FeedbackSection';
  import ReportedDurationBlock
    from '@/components/assignment_components/one_assignment/interactive_info/pt_feedback/feedback_sections/reported_duration_section/ReportedDurationBlock';

  export default {
    components: {
      'feedback-section': FeedbackSection,
      'reported-duration': ReportedDurationBlock,
    },
    props: {
      withIcon: {
        type: Boolean,
        default: true
      },
      showLogText: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        progressActive: false
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userIsInterpreter',
      ]),
      ...mapGetters('OneAssignmentStore', [
        'jobFeedback',
        'currentDiscussion'
      ]),
      ...mapState('OneAssignmentStore', {
        assignmentObj: ({storeJobObj}) => storeJobObj || {}
      }),
      ...mapState('FeedbackStore', {
        startTime: ({feedback}) => feedback.startTime,
        finishTime: ({feedback}) => feedback.finishTime,
        timeStep: ({feedback}) => feedback.timeStep || 5,
        delayCausedBy: ({feedback}) => feedback.delayCausedBy,
        lastUpdatedData: ({lastUpdatedData}) => lastUpdatedData || {},
        agreeWithReportedDate: ({feedback}) => feedback.agreeWithReportedDate,
        // errors
        durationValidationErrors: ({feedback}) => feedback.durationValidationErrors || [],
      }),
      ...mapGetters('FeedbackStore', [
        'agreeWithDate',
        'disAgreeWithDate',
        'otherSideFeedback',
        'otherSideStartTime',
        'otherSideFinishTime',
        'otherSideAgreedWithReportedDate'
      ]),
      disableButton() {
        if (this.agreeWithReportedDate === null) {
          if (typeof window !== 'undefined') {
            if (localStorage.agreeWithReportedDate !== undefined) {
              if (localStorage.agreeWithReportedDate === 'false') this.setAgreeWithReportedDate(false);
              if (localStorage.agreeWithReportedDate === 'true') this.setAgreeWithReportedDate(true);

              return !this.agreeWithReportedDate;
            }
          }
          return false;
        }
        return !this.agreeWithReportedDate;
      },
      lastUpdatedSectionData() {
        return this.userIsInterpreter
          ? this.lastUpdatedData.supplier_feedback_updated_assignment_duration
          : this.lastUpdatedData.demander_feedback_updated_assignment_duration;
      },
      title() {
        if (this.userIsInterpreter) {
          return this.interpreterReportedPeriod ? this.$gettext('The customer reported the following duration') : this.$gettext('Confirm the assignment duration');
        } else { return this.interpreterReportedPeriod ? this.$gettext('The interpreter reported the following duration') : this.$gettext('Confirm the assignment duration'); }
      },
      durationText() {
        const duration = this.$moment(this.finishTime).diff(this.$moment(this.startTime), 'minutes');
        if (duration <= 1440 && duration > 0) {
          return helpers.getJobInfo.durationText(duration, this);
        } else if (duration > 1440) return `<p class="sk-input__errors-cont" >${this.$gettext('You can\'t report more then 24h')} </p>`;
        else return `<p class="sk-input__errors-cont" >${this.$gettext('You can\'t report negative number of hours')} </p>`;
      },
      iAgreeText() {
        return this.interpreterReportedPeriod ? this.$gettext('I agree with the reported time') : this.$gettext('I agree');
      },
      iDisagreeText() {
        return this.interpreterReportedPeriod ? this.$gettext('I disagree with the reported time') : this.$gettext('I disagree');
      },
      lastUpdatedText() {
        if (this.showLogText) {
          return helpers.feedbackInfo.lastUpdatedText({
            context: this,
            lastUpdatedSectionData: this.lastUpdatedSectionData
          });
        }
      },
      assignmentRequirements() {
        return this.assignmentObj.interpretationRequirement || {};
      },
      feedbackUpdateAllowed() {
        return this.assignmentObj.feedbackUpdateAllowed;
      },
      feedbackId() {
        return this.jobFeedback.id || '';
      },
      feedbackUpdatedAt() {
        return this.jobFeedback.updatedAt || '';
      },
      interpreterReportedPeriod() {
        return this.otherSideStartTime && this.otherSideFinishTime;
      },
      updatedSupplierFeedback() {
        const startTimesMatched = this.matchTimes(this.otherSideStartTime, this.startTime);
        const finishTimesMatched = this.matchTimes(this.otherSideFinishTime, this.finishTime);
        const feedbacksTimesMatched = startTimesMatched && finishTimesMatched;
        const otherSideFeedbackUpdatedAt = this.otherSideFeedback?.updatedAt;

        return this.feedbackUpdatedAt && otherSideFeedbackUpdatedAt && !this.userIsInterpreter && !feedbacksTimesMatched
          ? this.$moment(this.feedbackUpdatedAt).diff(otherSideFeedbackUpdatedAt, 'minutes') < 0
          : false;
      },
      startTimeText() {
        return this.$gettext('When did the assignment start?');
      },
      startTimeDate() {
        if (this.otherSideStartTime) {
          const startTime = this.$moment(this.otherSideStartTime).format('HH:mm');
          const template = this.userIsInterpreter ? this.$gettext('Customer reported at %{time}') : this.$gettext('The interpreter reported at %{time}');
          return this.$gettextInterpolate(template, {time: startTime});
        } else {
          const startTime = this.$moment(this.assignmentObj.interpretationRequirement.startTime).format('HH:mm');
          const template = this.$gettext('Agreed start time was %{time}');
          return this.$gettextInterpolate(template, {time: startTime});
        }
      },
      finishTimeText() {
        return this.$gettext('When did the assignment end?');
      },
      finishTimeDate() {
        if (this.otherSideFinishTime) {
          const finishTime = this.$moment(this.otherSideFinishTime).format('HH:mm');
          const template = this.userIsInterpreter ? this.$gettext('Customer reported at %{time}') : this.$gettext('The interpreter reported at %{time}');
          return this.$gettextInterpolate(template, {time: finishTime});
        } else {
          const finishTime = this.$moment(this.assignmentObj.interpretationRequirement.finishTime).format('HH:mm');
          const template = this.$gettext('Agreed finish time was %{time}');
          return this.$gettextInterpolate(template, {time: finishTime});
        }
      },
      sectionIcon() {
        return this.withIcon ? 'duration' : '';
      },
      hideSave() {
        const duration = this.$moment(this.finishTime).diff(this.$moment(this.startTime), 'minutes');
        return !this.disAgreeWithDate || duration > 1440 || duration <= 0;
      }
    },
    watch: {
      assignmentRequirements() {
        this.preselectAssignmentTime();
      }
    },
    methods: {
      ...mapMutations('FeedbackStore', [
        'setAgreeWithReportedDate',
        'setFeedbackInitialTime',
        'setFeedbackStartTime',
        'setFeedbackFinishTime',
        'setFeedbackFinishDate',
        'setFeedbackDelayCausedBy'
      ]),
      ...mapActions('FeedbackStore', [
        'setFeedbackError',
        'removeOneFeedbackError',
        'removeFeedbackErrors'
      ]),
      matchTimes(timeValue1, timeValue2) {
        const timeValueMoment1 = timeValue1 ? this.$moment(timeValue1).format('DD/MM/YYYY HH:mm') : '';
        const timeValueMoment2 = timeValue2 ? this.$moment(timeValue2).format('DD/MM/YYYY HH:mm') : '';
        return timeValueMoment1 === timeValueMoment2;
      },
      agreeWithReportedDateMethod() {
        if (this.agreeWithDate !== true) {
          this.setAgreeWithReportedDate(true);
          this.save();
        }
      },
      preselectAssignmentTime() {
        const startTime = this.$moment(this.jobFeedback.reportedStartTime
          || this.otherSideStartTime
          || this.assignmentRequirements.startTime);
        const finishTime = this.$moment(this.jobFeedback.reportedFinishTime
          || this.otherSideFinishTime
          || this.assignmentRequirements.finishTime);

        this.setFeedbackInitialTime({
          startTime,
          finishTime
        });
      },
      preselectDelayCausedBy() {
        this.setFeedbackDelayCausedBy(this.jobFeedback.delayCausedBy || '');
      },
      setFeedbackInfo() {
        this.preselectAssignmentTime();
        this.preselectDelayCausedBy();
      },
      setProgress(value) {
        this.progressActive = value;
      },
      validateForm() {
        let isValidForm = true;
        this.removeFeedbackErrors();
        if (!this.agreeWithDate && !this.delayCausedBy) {
          isValidForm = false;
          this.setFeedbackError({
            fieldName: 'delayCausedBy',
            errorText: this.$gettext('Please select a option')
          });
        }
        return isValidForm;
      },
      save() {
        if (this.validateForm()) {
          const params = {
            discussionId: this.currentDiscussion.id,
            feedbackId: this.feedbackId,
            feedback: {
              actual_start_time: this.startTime || this.assignmentRequirements.startTime,
              actual_finish_time: this.finishTime || this.assignmentRequirements.finishTime,
            }
          };
          if (this.agreeWithDate === false) params.feedback.delay_caused_by = this.delayCausedBy;
          const action = this.feedbackId ? 'FeedbackStore/updateFeedbackV3' : 'FeedbackStore/sendFeedbackV3';
          this.setProgress(true);
          this.$store.dispatch(action, params).then(() => {
            this.setProgress(false);
          }).catch(() => {
            this.setProgress(false);
          });
        }
      }
    },
    mounted() {
      this.setFeedbackInfo();
    }
  };
</script>

<style scoped>
.reported-duration_date-wrapper {
  display: flex;
  justify-content: space-around;
  margin: 25px 0;
}

.reported-duration_date {
  margin-top: 10px;
  color: #008489;
  font-size: 12px;
}

.reported-duration__time--duration {
  display: flex;
  justify-content: space-between;
  margin: 10px 50px 15px 5px;
}

.reported-duration__duration-title,
.reported-duration__duration-text {
  font-weight: bold;
}

.reported-duration__action-btns-wrapper {
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;
}

.feedback__toggle-btn {
  display: block;
  width: 100%;
  padding: 7px;
  white-space: normal;
}

.reported-duration-section__wrapper {
  margin-top: -5px;
}

.reported-duration_date--start {
  padding-right: 14px;
}
</style>
