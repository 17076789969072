// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/checkboxes_icons/star_rate.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@assets/imgs/checkboxes_icons/star_rate_checked.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "\n.rate[data-v-32dce0d3] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n  width: 100%;\n  max-height: 40px;\n}\n.rate__title[data-v-32dce0d3] {\n  padding-right: 12px;\n}\n.rate__cont[data-v-32dce0d3] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  direction: rtl;\n}\n.rate__star[data-v-32dce0d3] {\n  display: inline-block;\n  width: 20px;\n  height: 20px;\n  padding: 1px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-clip: content-box;\n  background-size: 20px auto;\n  background-repeat: no-repeat;\n}\n.rate--editable .rate__star[data-v-32dce0d3] {\n  cursor: pointer;\n}\n.rate__star.is-filled[data-v-32dce0d3],\n.rate--editable .rate__star[data-v-32dce0d3]:hover,\n.rate--editable .rate__star:hover ~ .rate__star[data-v-32dce0d3] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n.rate--with-error[data-v-32dce0d3] {\n  padding: 5px;\n  border: 1px solid var(--color-error);\n  border-radius: 4px;\n}\n", ""]);
// Exports
module.exports = exports;
