(<template>
  <feedback-section :title="title"
                    :icon="'complete'"
                    :progress-active="progressActive"
                    :hide-button="hideButtonSave"
                    class="assignment-completion"
                    @save="saveNotTravelling">
    <div class="assignment-completion__btns-wrapper">
      <button class="sk-btn feedback__toggle-btn feedback__toggle-btn--half"
              :class="{'sk-btn--default': isTravelling, 'sk-btn--white': !isTravelling}"
              @click="setTravelling('right')">{{ $gettext('Yes') }}</button>
      <button class="sk-btn feedback__toggle-btn feedback__toggle-btn--half"
              :class="{'sk-btn--default': isNotTravelling, 'sk-btn--white': !isNotTravelling}"
              @click="setTravelling('wrong')">{{ $gettext('No') }}</button>
    </div>
  </feedback-section>
</template>)

<script>
  import {mapGetters, mapMutations, mapState} from 'vuex';
  import FeedbackSection
    from '@/components/assignment_components/one_assignment/interactive_info/pt_feedback/FeedbackSection';
  import SendFeedbackForCancelledAssignment from '@/mixins/SendFeedbackForCancelledAssignment';

  export default {
    components: {
      'feedback-section': FeedbackSection
    },
    mixins: [SendFeedbackForCancelledAssignment],
    data() {
      return {
        progressActive: false
      };
    },
    computed: {
      ...mapState('FeedbackStore', {
        interpreterTravelled: (state) => state.feedbackCancelledAssignment.interpreterTravelled,
      }),
      ...mapGetters('OneAssignmentStore', [
        'currentDiscussion',
        'cancelledJobFeedback'
      ]),
      ...mapGetters('FeedbackStore', [
        'isTravelling',
        'isNotTravelling'
      ]),
      title() {
        return this.$gettext('Did you start traveling?');
      },
      hideButtonSave() {
        return this.interpreterTravelled === 'right' || !this.interpreterTravelled;
      }
    },
    methods: {
      ...mapMutations('FeedbackStore', [
        'setTravelling',
        'setNoTravelling',
      ]),
      setFeedbackInfo() {
        this.setTravelling(this.cancelledJobFeedback.interpreterTravelled);
      },
      saveNotTravelling() {
        this.setNoTravelling();
        this.sendFeedback(this.currentDiscussion.id);
      }
    },
    mounted() {
      this.setFeedbackInfo();
    }
  };
</script>

<style scoped>
.assignment-completion__btns-wrapper {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 10px;
}

.feedback__toggle-btn {
  margin-right: 4px;
  padding: 9px 10px;
  white-space: normal;
}

.feedback__toggle-btn--half {
  width: 45%;
}

@media (max-width: 550px) {
  .feedback__toggle-btn {
    line-height: 20px;
  }
}
</style>
