(<template>
  <feedback-section :title="title"
                    :icon="'personal-feedback-interpreter-side'"
                    :show-button="showSaveButton"
                    :button-description="lastUpdatedText"
                    :progress-active="progressActive"
                    class="personal-feedback"
                    @save="save">
    <template v-if="showPublicFeedback">
      <div>
        <pre class="feedback__field personal-feedback__feedback personal-feedback__feedback--light">{{ publicFeedbackComment }}</pre>
      </div>
      <template v-if="showAllowedBySupplierCheckbox">
        <sk-checkbox :preselected-value="publicFeedbackAllowedBySupplier"
                     :item-text="publicFeedbackAllowedBySupplierText"
                     class="feedback__field sk-checkbox--top-alignment personal-feedback__checkbox"
                     @checkboxvaluechanged="setPublicFeedbackAllowedBySupplier" />
        <div class="feedback__field personal-feedback__note">{{ $gettext('The feedback will be shown on the profile page after we lock this assignment.') }}</div>
      </template>
      <div v-else
           class="personal-feedback__note">{{ $gettext('The customer has not allowed this comment to be published on your profile page for anyone to read.') }}</div>
    </template>
    <div v-else
         class="feedback__field personal-feedback__feedback personal-feedback__feedback--dark">
      {{ $gettext('If the customer gives you some personal feedback it will appear here. Please come back later.') }}
    </div>
  </feedback-section>
</template>)

<script>
  import {mapGetters, mapMutations, mapState} from 'vuex';
  import helpers from '@/helpers';
  import FeedbackSection
    from '@/components/assignment_components/one_assignment/interactive_info/pt_feedback/FeedbackSection';

  export default {
    components: {
      'feedback-section': FeedbackSection
    },
    data() {
      return {
        progressActive: false
      };
    },
    computed: {
      ...mapGetters('OneAssignmentStore', [
        'jobFeedback'
      ]),
      ...mapState('FeedbackStore', {
        publicFeedbackAllowedBySupplier: ({feedback}) => feedback.publicFeedback.allowedBySupplier,
        publicFeedbackAllowedByDemander: ({feedback}) => feedback.publicFeedback.allowedByDemander,
        publicFeedbackId: ({feedback}) => feedback.publicFeedback.id,
        publicFeedbackComment: ({feedback}) => feedback.publicFeedback.comment || '',
        lastUpdatedSectionData: ({lastUpdatedData}) => lastUpdatedData?.supplier_feedback_updated_interpreter
      }),
      title() {
        return this.$gettext('Feedback from the customer');
      },
      lastUpdatedText() {
        return helpers.feedbackInfo.lastUpdatedText({
          context: this,
          lastUpdatedSectionData: this.lastUpdatedSectionData
        });
      },
      feedbackId() {
        return this.jobFeedback.id || '';
      },
      showPublicFeedback() {
        return this.publicFeedbackId && !!this.publicFeedbackComment;
      },
      publicFeedbackAllowedBySupplierText() {
        return this.$gettext('I want to show the feedback from the customer on my profile page so that other customers can read it');
      },
      showAllowedBySupplierCheckbox() {
        return !!(this.showPublicFeedback && this.publicFeedbackAllowedByDemander);
      },
      showSaveButton() {
        return this.showAllowedBySupplierCheckbox;
      }
    },
    methods: {
      ...mapMutations('FeedbackStore', [
        'setPublicFeedbackComment',
        'setPublicFeedbackAllowedBySupplier'
      ]),
      createForm() {
        const form = new FormData();

        if (this.feedbackId) {
          form.append('feedback[id]', this.feedbackId);
        }
        if (this.publicFeedbackId) {
          form.append('feedback[public_feedback][id]', this.publicFeedbackId);
        }
        if (this.showAllowedBySupplierCheckbox) {
          form.append('feedback[public_feedback][allowed_by_supplier]', this.publicFeedbackAllowedBySupplier);
        }
        form.append('feedback[track_type]', 'supplier_feedback_updated_interpreter');

        return form;
      },
      setProgress(value) {
        this.progressActive = value;
      },
      save() {
        this.$emit('submitsection', this.createForm(), this.setProgress);
      }
    }
  };
</script>

<style scoped>
  .personal-feedback__feedback {
    padding: 10px;
    border-radius: 3px;
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }

  .personal-feedback__feedback--dark {
    background-color: #e9ddcc;
  }

  .personal-feedback__feedback--light {
    background-color: #cce6e7;
  }

  .personal-feedback__note {
    color: #b68e56;
  }

</style>
