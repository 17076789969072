(<template>
  <div class="travel-expense">
    <div class="travel-expense__title">{{ title }}</div>
    <template v-for="expenseItem in expensesList">
      <div :key="expenseItem.key"
           class="travel-expense__item">
        <a v-if="expenseItem.receiptExtension"
           :href="expenseItem.receipt"
           target="_blank"
           class="sk-link sk-link--gray-bg travel-expense__link">{{ getReceiptName(expenseItem.receiptExtensionName) }}</a>
        <div class="travel-expense__price">{{ getTravelAmount(expenseItem.amount) }}</div>
        <button class="travel-expense__remove-btn"
                @click="$emit('deleteexpense', expenseItem.id)"></button>
      </div>
      <div v-if="adminMode"
           :key="`admin-mode_${expenseItem.key}`"
           class="travel-expense__items-wrapper">
        <div class="travel-expense__items">
          <div class="travel-expense__item">
            <div>{{ $gettext('The customer pays:') }}</div>
            <div class="travel-expense__price">{{ getTravelAmount(expenseItem.demanderApprovedAmount) }}</div>
          </div>
          <div class="travel-expense__item">
            <div>{{ $gettext('The interpreter gets paid:') }}</div>
            <div class="travel-expense__price">{{ getTravelAmount(expenseItem.supplierApprovedAmount) }}</div>
          </div>
        </div>
        <button class="sk-btn sk-btn--white travel-expense__edit-btn"
                @click="$emit('editexpense', expenseItem)">{{ $gettext('Edit') }}
        </button>
      </div>
    </template>
    <div class="travel-expense__item travel-expense__item--note">
      <div>{{ maxExpensesAmountText }}</div>
      <div class="travel-expense__price">{{ getTravelAmount(maxExpensesAmount) }}</div>
    </div>
    <div class="travel-expense__item">
      <div>{{ approvedExpensesAmountText }}</div>
      <div class="travel-expense__price">{{ getTravelAmount(approvedExpensesAmount) }}</div>
    </div>
  </div>
</template>)

<script>
  import {mapGetters} from 'vuex';
  import helpers from '@/helpers';

  export default {
    props: {
      adminMode: Boolean,
      expense: {
        type: Object,
        default: () => {
          return {
            type: '',
            amount: '',
            supplierApprovedAmount: '',
            demanderApprovedAmount: '',
            expenses: []
          };
        }
      }
    },
    data() {
      return {
        progressActive: false
      };
    },
    computed: {
      expensesList() {
        const expenses = this.expense.expenses || [];

        return expenses.map((expense = {}) => {
          expense.key = this.adminMode ? `admin_${Math.random()}` : Math.random();
          expense.receiptExtensionName = (expense.receiptExtension || '').slice(1);

          return expense;
        });
      },
      ...mapGetters('UserInfoStore', [
        'userCurrency'
      ]),
      currency() {
        return this.userCurrency?.name || 'NOK';
      },
      title() {
        return helpers.getJobInfo.getTravelExpensesName(this, this.expense.type || '') || '';
      },
      approvedExpensesAmount() {
        return this.expense.supplierApprovedAmount || 0;
      },
      approvedExpensesAmountText() {
        const travelExpensesTypeName = this.title.toLowerCase();
        const template = this.$gettext('Approved %{travelExpensesTypeName} costs');

        return this.$gettextInterpolate(template, {
          travelExpensesTypeName
        });
      },
      maxExpensesAmount() {
        return this.expense.maxAmount || 0;
      },
      maxExpensesAmountText() {
        const travelExpensesTypeName = this.title.toLowerCase();
        const template = this.$gettext('Maximum refundable %{travelExpensesTypeName} costs');

        return this.$gettextInterpolate(template, {
          travelExpensesTypeName
        });
      },
    },
    methods: {
      getTravelAmount(amount = '') {
        return `${amount || '0.0'} ${this.currency}`;
      },
      getReceiptName(receiptExtension = 'DOC') {
        return `[${receiptExtension.toUpperCase()}]`;
      }
    }
  };
</script>

<style>
  .travel-expense--admin .feedback-section__title {
    align-items: center;
    font-size: 14px;
  }
</style>

<style scoped>
  .travel-expense {
    width: 100%;
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 5px;
    background: #f4f5f7;
  }

  .travel-expense__title {
    margin-bottom: 10px;
    font-weight: bold;
  }

  .travel-expense__items-wrapper {
    position: relative;
    display: flex;
    align-items: flex-end;
    margin-bottom: 30px;
  }

  .travel-expense__items-wrapper::after {
    content: '';
    position: absolute;
    bottom: -15px;
    width: 100%;
    height: 1px;
    background-color: rgba(43, 37, 92, 0.1);
  }

  .travel-expense__item {
    display: flex;
    align-items: center;
    margin-top: 5px;
    word-break: break-all;
  }

  .travel-expense__item--note {
    color: #b68e56;
  }

  .travel-expense__link {
    padding-right: 5px;
    font-weight: normal;
    white-space: nowrap;
    word-break: normal;
  }

  .travel-expense__price {
    margin-left: auto;
    padding-left: 10px;
    font-weight: bold;
    white-space: nowrap;
    word-break: normal;
  }

  .travel-expense__remove-btn {
    width: 15px;
    min-width: 15px;
    height: 15px;
    margin-left: 5px;
    background-image: url(~@assets/imgs/undefined_imgs/trash_ico_black.svg);
    background-position: 50% 50%;
    background-size: 10px auto;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  .travel-expense__edit-btn {
    width: auto;
    margin-left: auto;
    padding: 0 10px;
  }
</style>
