(<template>
  <div class="reported-duration">
    <h3 class="reported-duration__title">{{ changeReportedText }}</h3>
    <div class="reported-duration__cont">
      <div class="reported-duration__time">
        <div class="reported-duration__duration-title-wrapper">
          <p class="reported-duration__duration-title">{{ startTimeText }}</p>
        </div>
        <div class="reported-duration__date-time-wrapper">
          <sk-datepicker :preselected-value="assignmentStartDate"
                         :default-value="'--/--/----'"
                         :full-calendar="true"
                         :prevent-call="true"
                         :no-description="true"
                         :max-month="maxMonth"
                         :disabled="true"
                         class="reported-duration__date" />
          <sk-timepicker :disabled="disableEditing"
                         :preselected-value="feedbackStartTime"
                         :auto-scroll="true"
                         :default-value="'--:--'"
                         :time-step="timeStep"
                         :mob-numeric-keyboard="true"
                         :no-description="true"
                         class="reported-duration__timepicker"
                         @timepickervaluechanged="setStartTime" />
        </div>
      </div>
      <div class="reported-duration__time">
        <div class="reported-duration__duration-title-wrapper">
          <p class="reported-duration__duration-title">{{ finishTimeText }}</p>
        </div>
        <div class="reported-duration__date-time-wrapper">
          <sk-datepicker :preselected-value="assignmentFinishDate"
                         :default-value="'--/--/----'"
                         :full-calendar="true"
                         :prevent-call="true"
                         :no-description="true"
                         :max-month="maxMonth"
                         :disabled="disableEditing"
                         class="reported-duration__date"
                         @changeday="setFinishDate" />
          <sk-timepicker :disabled="disableEditing"
                         :preselected-value="feedbackFinishTime"
                         :auto-scroll="true"
                         :default-value="'--:--'"
                         :time-step="timeStep"
                         :mob-numeric-keyboard="true"
                         :start-select-from="finishTimeBeginning"
                         :no-description="true"
                         class="reported-duration__timepicker"
                         @timepickervaluechanged="setFinishTime" />
        </div>
      </div>
      <div v-if="errors.length"
           class="sk-input__errors-cont">
        <span v-for="(error, index) in errors"
              :key="index"
              v-html="(errors.length === 1) ? error : `${index + 1}. ${error}`"></span>
      </div>
    </div>
    <div class="reported-duration__delay-cause">
      <div :class="{'is-with-error': showDelayCausedByErrors}">
        <p class="reported-duration__delay-cause-title">{{ delayCausedText }}</p>
        <sk-radio v-for="delayCausedByItem in delayCauses"
                  :key="delayCausedByItem.id"
                  :item-text="delayCausedByItem.name"
                  :item-value="delayCausedByItem.id"
                  :preselected-value="delayCausedBy"
                  :item-name="'delay-caused-by'"
                  class="reported-duration__delay-cause-radio"
                  @radiochanged="setFeedbackDelayCausedBy" />
      </div>
      <div v-if="showDelayCausedByErrors"
           class="sk-input__errors-cont assignment-duration-section__radios-errors">
        <span v-for="(error, index) in delayCausedByErrors"
              :key="index"
              v-html="(delayCausedByErrors.length === 1) ? error : `${index + 1}. ${error}`"></span>
      </div>
    </div>
  </div>
</template>)

<script>
  import {mapGetters, mapMutations, mapState} from 'vuex';
  import helpers from '@/helpers';

  export default {
    props: {
      startTime: {
        type: [String, Object],
        default: ''
      },
      finishTime: {
        type: [String, Object],
        default: ''
      },
      otherSideStart: {
        type: String,
        default: ''
      },
      otherSideFinish: {
        type: String,
        default: ''
      },
      timeStep: {
        type: Number,
        default: 5
      },
      errors: {
        type: Array,
        default: () => []
      },
      disableEditing: {
        type: [Boolean, undefined],
        default: undefined
      },
      updatedSupplierFeedback: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userIsInterpreter',
      ]),
      ...mapState('FeedbackStore', {
        delayCausedBy: ({feedback}) => feedback.delayCausedBy,
        // errors
        delayCausedByErrors: ({feedback}) => feedback.errors.delayCausedBy.errors || []
      }),
      showDelayCausedByErrors() {
        return !!this.delayCausedByErrors.length;
      },
      startTimeText() { return this.$gettext('When did the assignment start?'); },
      finishTimeText() { return this.$gettext('When did the assignment finish?'); },
      reportSideText() { return this.userIsInterpreter ? this.$gettext('demander') : this.$gettext('supplier'); },
      maxMonth() { return this.$moment().add(1, 'month'); },
      assignmentFinishDate() { return this.finishTime ? this.$moment(this.finishTime).format('YYYY-MM-DD') : ''; },
      assignmentStartDate() { return this.startTime ? this.$moment(this.startTime).format('YYYY-MM-DD') : ''; },
      otherSideStartTimeText() {
        const time = this.$moment(this.otherSideStart).format('HH:mm');
        return this.getReportedTimeText(this.reportSideText, time);
      },
      otherSideFinishTimeText() {
        const time = this.$moment(this.otherSideFinish).format('HH:mm');
        return this.getReportedTimeText(this.reportSideText, time);
      },
      feedbackStartTime() { return this.startTime ? this.$moment(this.startTime).format('HH:mm') : ''; },
      feedbackFinishTime() { return this.finishTime ? this.$moment(this.finishTime).format('HH:mm') : ''; },
      isCurrentFinishDay() { return this.assignmentFinishDate === this.assignmentStartDate; },
      finishTimeBeginning() {
        return this.isCurrentFinishDay
          ? this.$moment(this.startTime).add(this.timeStep, 'minutes').format('HH:mm')
          : '';
      },
      changeReportedText() {
        return this.$gettext('Report the duration of the assignment');
      },
      delayCausedText() {
        return this.$gettext('Who changed the schedule?');
      },
      defaultCausedBy() {
        return this.$gettext('select cause');
      },
      delayCauses() {
        return helpers.feedbackInfo.delayCausedByList(this);
      },
    },
    methods: {
      ...mapMutations('FeedbackStore', [
        'setFeedbackDelayCausedBy'
      ]),
      getReportedTimeText(side, time) {
        const template = this.$gettext('The %{side} reported %{time}');
        return this.$gettextInterpolate(template, {side, time});
      },
      setFinishDate(value) {
        const newDate = helpers.dateTime.setDate(this.$moment, {
          currentTime: this.finishTime,
          value
        });
        this.$emit('setfinishdate', newDate);
      },
      setStartTime(value) {
        this.$emit('setstarttime', value);
      },
      setFinishTime(value) {
        this.$emit('setfinishtime', value);
      }
    }
  };
</script>

<style>
  .reported-duration__date .sk-month-calendar__wrapper.sk-date-picker__calendar {
    top: -2px;
    left: -230px;
    margin-top: 0;
    transform: translate(0, -100%);
  }
</style>

<style scoped>
.reported-duration__title {
  margin-bottom: 20px;
  font-size: 18px;
}

.reported-duration__date-time-wrapper {
  display: flex;
}

.reported-duration__cont {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.reported-duration__time {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 100%;
  margin-bottom: 10px;
}

.reported-duration__duration-title-wrapper {
  display: flex;
  margin-right: 5px;
  margin-bottom: 15px;
}

.reported-duration__date {
  flex-shrink: 0;
  width: 110px;
  margin-left: auto;
  background-color: white;
}

.reported-duration__timepicker {
  width: 75px;
  min-width: 60px;
  margin-left: 10px;
  background-color: white;
}

.reported-duration__delay-cause {
  margin-top: 20px;
}

.reported-duration__delay-cause-title {
  margin-bottom: 10px;
  font-weight: bold;
}

.reported-duration__delay-cause-radio {
  margin-right: 8px;
}

.reported-duration__other-side-time {
  display: block;
  width: 100%;
  color: #008489;
}

@media (max-width: 767px) {
  .reported-duration__time {
    flex-wrap: wrap;
  }

  .reported-duration__duration-title-wrapper {
    width: 100%;
    margin-right: 0;
    margin-bottom: 5px;
  }

  .reported-duration__date {
    margin-left: 0;
  }

  .reported-duration__timepicker {
    margin-right: auto;
  }
}
</style>
