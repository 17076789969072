(<template>
  <div class="personal-feedback-section">
    <personal-feedback-supplier-side v-if="userIsInterpreter"
                                     @submitsection="save" />
    <personal-feedback-buyer-side v-else
                                  @submitsection="save" />
  </div>
</template>)

<script>
  import {mapGetters, mapMutations, mapState} from 'vuex';
  import PersonalFeedbackSupplierSide
    from '@/components/assignment_components/one_assignment/interactive_info/pt_feedback/feedback_sections/personal_feedback_section/PersonalFeedbackSupplierSide';
  import PersonalFeedbackBuyerSide
    from '@/components/assignment_components/one_assignment/interactive_info/pt_feedback/feedback_sections/personal_feedback_section/PersonalFeedbackBuyerSide';

  export default {
    components: {
      'personal-feedback-buyer-side': PersonalFeedbackBuyerSide,
      'personal-feedback-supplier-side': PersonalFeedbackSupplierSide
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userIsInterpreter',
        'userIsNotInterpreter',
      ]),
      ...mapGetters('OneAssignmentStore', [
        'storeJob',
      ]),
      ...mapState('FeedbackStore', {
        communicationRateId: ({feedback}) => feedback.communicationRateId,
        professionalRateId: ({feedback}) => feedback.professionalRateId,
        publicFeedbackComment: ({feedback}) => feedback.publicFeedback.comment || '',
        publicFeedbackAllowedByDemander: ({feedback}) => feedback.publicFeedback.allowedByDemander,
        publicFeedbackAllowedBySupplier: ({feedback}) => feedback.publicFeedback.allowedBySupplier,
      }),
    },
    methods: {
      ...mapMutations('FeedbackStore', [
        'setPublicFeedbackComment',
        'setPublicFeedbackAllowedBySupplier',
        'setPublicFeedbackAllowedByDemander',
        'setPublicFeedbackId',
        'setFeedbackFinished'
      ]),
      setPublicFeedbackInfo() {
        const publicFeedback = this.storeJob.publicFeedback || {};

        this.setPublicFeedbackId(publicFeedback.id || '');
        this.setPublicFeedbackComment(publicFeedback.comment || '');
        this.setPublicFeedbackAllowedBySupplier(!!publicFeedback.allowedBySupplier);
        this.setPublicFeedbackAllowedByDemander(!!publicFeedback.allowedByDemander);
      },
      save(form, setProgressFunc) {
        if (this.userIsNotInterpreter) {
          const feedback = {
            public_feedback: {
              id: this.storeJob.publicFeedback.id,
              comment: this.publicFeedbackComment,
              allowed_by_demander: this.publicFeedbackAllowedByDemander
            },
            feedback_finished: true
          };
          if (this.communicationRateId) feedback.communication = this.communicationRateId;
          if (this.professionalRateId) feedback.professionalism = this.professionalRateId;
          this.setFeedbackFinished(true);
          this.$emit('submitsectionv3', feedback, setProgressFunc);
        } else {
          const feedback = {
            public_feedback: {
              id: this.storeJob.publicFeedback.id,
              allowed_by_supplier: this.publicFeedbackAllowedBySupplier
            }
          };
          this.$emit('submitsectionv3', feedback, setProgressFunc);
        }
      }
    },
    mounted() {
      this.setPublicFeedbackInfo();
    }
  };
</script>
