(<template>
  <feedback-section :title="title"
                    :icon="'personal-feedback'"
                    :button-description="lastUpdatedText"
                    :progress-active="progressActive"
                    class="personal-feedback"
                    @save="save">
    <set-rating :title="communicationText"
                :rate="communicationRateId"
                :can-edit="true"
                :validation-name="communicationErrorName"
                class="feedback__field personal-feedback__rate"
                @ratechange="setFeedbackCommunicationRateId" />
    <set-rating :title="professionalismText"
                :rate="professionalRateId"
                :can-edit="true"
                :validation-name="professionalismErrorName"
                class="feedback__field personal-feedback__rate"
                @ratechange="setFeedbackProfessionalRateId" />
    <div :class="{'in-progress': relationProgressActive}"
         class="is-progress-bar">
      <add-to-favourite :description="favouriteBtnText"
                        :person="interpreterData"
                        class="feedback__field"
                        @startprogress="relationProgressActive = true"
                        @relationchanged="onRelationChanged" />
      <add-to-blocked :description="blockBtnText"
                      :person="interpreterData"
                      class="feedback__field"
                      @startprogress="relationProgressActive = true"
                      @relationchanged="onRelationChanged" />
    </div>
    <public-feedback-block />
  </feedback-section>
</template>)

<script>
  import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
  import helpers from '@/helpers';
  import FeedbackSection from '@/components/assignment_components/one_assignment/interactive_info/pt_feedback/FeedbackSection';
  import Rating from '@/components/shared_components/Rating';
  import AddToFavourite from '@/components/shared_components/AddToFavourite';
  import AddToBlocked from '@/components/shared_components/AddToBlocked';
  import PublicFeedbackBlock from '@/components/assignment_components/one_assignment/interactive_info/pt_feedback/feedback_sections/personal_feedback_section/PublicFeedbackBlock';

  export default {
    components: {
      'public-feedback-block': PublicFeedbackBlock,
      'feedback-section': FeedbackSection,
      'set-rating': Rating,
      'add-to-favourite': AddToFavourite,
      'add-to-blocked': AddToBlocked
    },
    data() {
      return {
        progressActive: false,
        relationProgressActive: false,
        firstClickOnButtonSave: true
      };
    },
    computed: {
      ...mapGetters('OneAssignmentStore', [
        'currentDiscussion',
        'jobFeedback'
      ]),
      ...mapState('FeedbackStore', {
        communicationRateId: ({feedback}) => feedback.communicationRateId,
        professionalRateId: ({feedback}) => feedback.professionalRateId,
        publicFeedbackComment: ({feedback}) => feedback.publicFeedback.comment || '',
        publicFeedbackId: ({feedback}) => feedback.publicFeedback.id || '',
        publicFeedbackAllowedByDemander: ({feedback}) => feedback.publicFeedback.allowedByDemander,
        lastUpdatedSectionData: ({lastUpdatedData}) => lastUpdatedData?.demander_feedback_updated_public_feedback,
        // errors
        communicationErrorName: ({feedback}) => feedback.errors.communication.name,
        professionalismErrorName: ({feedback}) => feedback.errors.professionalism.name
      }),
      title() {
        return this.$gettext('Feedback to interpreter');
      },
      lastUpdatedText() {
        return helpers.feedbackInfo.lastUpdatedText({
          context: this,
          lastUpdatedSectionData: this.lastUpdatedSectionData
        });
      },
      feedbackId() {
        return this.jobFeedback.id || '';
      },
      discussionId() {
        return this.currentDiscussion.id;
      },
      communicationText() {
        return this.$gettext('Communication');
      },
      professionalismText() {
        return this.$gettext('Professionalism');
      },
      interpreterData() {
        return this.currentDiscussion.supplierInfo || {};
      },
      interpreterName() {
        return this.interpreterData.name || '';
      },
      favouriteBtnText() {
        const template = this.$gettext('Add %{name} to your favourites');

        return this.$gettextInterpolate(template, {
          name: this.interpreterName
        });
      },
      blockBtnText() {
        const template = this.$gettext('Block %{name}');

        return this.$gettextInterpolate(template, {
          name: this.interpreterName
        });
      },
      feedbackInfoModal() {
        const title = `<h3 class="info-modal-title">${this.$gettext('Thank you for using the feedback form!')}</h3>`;
        const paragraph = `<p>${this.$gettext('Note that this action will end the feedback collection process. Please make sure all the information is correct before moving forward.')}</p>`;
        return title + paragraph;
      }
    },
    methods: {
      ...mapMutations('FeedbackStore', [
        'setFeedbackCommunicationRateId',
        'setFeedbackProfessionalRateId',
        'setPublicFeedbackComment',
        'setPublicFeedbackAllowedByDemander',
        'setFeedbackFinished'
      ]),
      ...mapActions('FeedbackStore', [
        'removeFeedbackErrors',
        'setFeedbackError'
      ]),
      onRelationChanged() {
        this.$store.dispatch('OneAssignmentStore/getOneDiscussion', this.discussionId)
          .then(() => {
            this.relationProgressActive = false;
          });
      },
      setFeedbackInfo() {
        this.setFeedbackCommunicationRateId(this.jobFeedback.communication || '');
        this.setFeedbackProfessionalRateId(this.jobFeedback.professionalism || '');
        this.setFeedbackFinished(this.jobFeedback.feedbackFinished || false);
      },
      createForm() {
        const form = new FormData();

        if (this.feedbackId) {
          form.append('feedback[id]', this.feedbackId);
        }
        if (this.professionalRateId) {
          form.append('feedback[professionalism]', this.professionalRateId);
        }
        if (this.communicationRateId) {
          form.append('feedback[communication]', this.communicationRateId);
        }
        if (this.publicFeedbackId) {
          form.append('feedback[public_feedback][id]', this.publicFeedbackId);
        }
        if (this.publicFeedbackComment) {
          form.append('feedback[public_feedback][comment]', this.publicFeedbackComment);
          form.append('feedback[public_feedback][allowed_by_demander]', this.publicFeedbackAllowedByDemander);
        }
        form.append('feedback[track_type]', 'demander_feedback_updated_interpreter');

        return form;
      },
      setProgress(value) {
        this.progressActive = value;
      },
      validate() {
        let isValidForm = true;

        this.removeFeedbackErrors();
        if (!this.communicationRateId) {
          isValidForm = false;
          this.setFeedbackError({
            fieldName: 'communication',
            errorText: this.$gettext('Please give communication rating')
          });
        }
        if (!this.professionalRateId) {
          isValidForm = false;
          this.setFeedbackError({
            fieldName: 'professionalism',
            errorText: this.$gettext('Please give professionalism rating')
          });
        }

        return isValidForm;
      },
      save() {
        if (this.validate()) {
          if (this.firstClickOnButtonSave) {
            this.$store.commit('InfoModalStore/setInfoModal', {
              text: this.feedbackInfoModal
            });
            this.firstClickOnButtonSave = false;
            return;
          }

          this.$emit('submitsection', this.createForm(), this.setProgress, this.setFeedbackInfo);
        }
      }
    },
    mounted() {
      this.setFeedbackInfo();
    }
  };
</script>

<style scoped>
  .personal-feedback__rate {
    justify-content: space-between;
  }
</style>

<style>
  .info-modal-title {
    margin-bottom: 20px;
  }
</style>
